import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip, ThemeProvider, createTheme } from "@mui/material";
import Button from "../../../Components/Button";
import { deleteServiceList, getServiceList } from "../../../Service";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import ServicePopup from "./ServicePopup";
import AlertBox from "../../../Components/AlertBox";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#161C29',
      paper: '#1c1c1e !important',
    },
    text: {
      primary: '#e0e0e0',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1e !important',
          '& .MuiBox-root': {
            backgroundColor: '#1c1c1e !important',
        },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important', // Dark background for header
          color: '#e0e0e0',           // Text color in dark mode
          borderBottom: '1px solid rgba(81, 81, 81, 0.5)',
          
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& td': {
              position: 'relative', 
              '&:after': {
                content: '""', 
                backgroundColor: '#242425 !important', 
              },
            },
        },
      },
    },
  },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#242425 !important',
          borderBottom: '1px solid #373737',
          color: '#e0e0e0', // Ensure text color is visible
          '& .MuiBox-root': {
            backgroundColor: '#242425 !important',
          },
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff', // Light background for the app
      paper: '#f5f5f5',   // Light background for paper elements like the table
    },
    text: {
      primary: '#000000', // Dark text color for light mode
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Light background for table container
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // Table cell background remains transparent
        },
      },
    },
  },
});

const Services = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [mode, setMode] = useState("");
  const [alert, setAlert] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [serviceData, setServiceData] = useState();
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const theme = useSelector((state) => state.theme.mode);

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        accessorKey: "service_name",
        header: "Name",
      },
      {
        accessorKey: "customer_count",
        header: "Customer Count",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        const data = await getServiceList();
        setData(data.services);
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(setActivePage("service"));

    fetchServiceList();
  }, [isAdded, dispatch]);

  function added() {
    setIsAdded(!isAdded);
  }

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setServiceData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteServiceList({
                      service_ids: [row.original.service_id],
                    });
                    setAlert(false);
                    added();
                  } catch {}
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{ gap: "15px" }}>
        <Button
          icon={addIcon}
          label="Add"
          type="primary"
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.service_id;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteServiceList({ service_ids: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });
  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        {/* <h4 className="sub-title">Tasks</h4> */}
      </div>
      <MaterialReactTable table={table} />
      <ServicePopup
        show={modalShow}
        mode={mode}
        serviceData={serviceData}
        added={added}
        onHide={() => setModalShow(false)}
      />
      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
    </div>
        </ThemeProvider>
  );
};

export default Services;
