import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import WanStatusGauge from "../../../Components/WanStatusGauge";
import TopTenChart from "../../../Components/TopTenChart";
import { dashboardWidgetGet, getDashboard } from "../../../Service";
import ThirtyDayGraph from "../../../Components/ThirtyDayGraph";
import Battery from "../../../Components/Battery";
import NetworkSpeed from "../../../Components/NetworkSpeed";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import { FiMaximize } from "react-icons/fi";
import RGL, { WidthProvider } from "react-grid-layout";
import EditImg from "../../../Assets/Images/edit-icon.svg";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const dashboardRef = useRef(null);

  const ReactGridLayout = WidthProvider(RGL);

  const theme = useSelector((state) => state.theme.mode);

  const goto = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage("dashboard"));
  }, [dispatch]);

  useEffect(() => {
    const fetchDashboard = async () => {
      setLoading(true);
      try {
        const data = await getDashboard();
        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchDashboard();
    const interval = setInterval(fetchDashboard, 2 * 60 * 1000); // 2 minutes

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchDashboardLayout = async () => {
      try {
        const data = await dashboardWidgetGet();
        setWidgets(data.widgets);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDashboardLayout();
  }, []);

  const handleFullscreenToggle = () => {
    const dashboard = dashboardRef.current;

    if (!document.fullscreenElement) {
      // Enter fullscreen mode
      dashboard
        .requestFullscreen()
        .then(() => {
          // Add fullscreen-specific styles
          dashboard.classList.add("dashboard-fullscreen");
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error(
            `Error attempting to enable fullscreen: ${err.message}`
          );
        });
    } else {
      // Exit fullscreen mode
      document.exitFullscreen().then(() => {
        // Remove fullscreen-specific styles
        dashboard.classList.remove("dashboard-fullscreen");
        setIsFullscreen(false);
      });
    }
  };

  const renderWidgetComponent = (id) => {
    switch (id) {
      case "wanStatus":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{ height: "390px", width: "100%" }}
          >
            <div className="mb-4 pb-2 graph-heading">SLA LAST 24 HOURS</div>
            <WanStatusGauge
              value={
                data.overall_percentage ? Number(data.overall_percentage) : 0
              }
            />
          </div>
        );
      case "battery":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper h-100"
            style={{ height: "390px", width: "100%" }}
          >
            <div className="mb-4 pb-2 graph-heading">
              UPS Battery Percentage
            </div>
            <Battery
              percentage={
                data.ups_percentage !== undefined ? data.ups_percentage : 0
              }
            />
          </div>
        );
      case "topAlerts":
        return (
          <div
            className="bg-white bg-space-radius dashboard-card-wraper table-style-dashboard h-100"
            style={{ height: "390px", width: "100%" }}
          >
            <div className="mb-4 pb-2 graph-heading"> Top Alerting Host</div>
            <TopTenChart data={data.top_nodes ? data.top_nodes : []} />
          </div>
        );
      case "downloadSpeed":
        return (
          <div className="bg-white bg-space-radius mb-4 h-100">
            <div className="mb-4 pb-2 graph-heading"> Download Speed</div>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <NetworkSpeed chartData={data.internet_download_speed} />
            )}
          </div>
        );
      case "uploadSpeed":
        return (
          <div className="bg-white bg-space-radius mb-4 h-100">
            <div className="mb-4 pb-2 graph-heading"> Upload Speed</div>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <NetworkSpeed chartData={data.internet_upload_speed} />
            )}
          </div>
        );
      case "thirtyDayGraph":
        return (
          <div className="bg-white bg-space-radius h-100">
            <div className="mb-4 pb-2 graph-heading"> SLA- Past 30 Days</div>
            <ThirtyDayGraph data={data.thirty_days_data} />
          </div>
        );
      default:
        return null;
    }
  };

  const layout = widgets.map((widget) => ({
    ...widget.layout,
    i: widget.id,
  }));

  return (
    <div ref={dashboardRef} style={isFullscreen ? { padding: "20px" } : {}}>
      <div className="d-flex align-items-center justify-content-between mb-2 respo-flex mt-2">
        {/* <h4 className="sub-title">{!isFullscreen ? "Overview" : ""}</h4> */}
        <div></div>
        <div className="d-flex align-items-center flex-column" style={{
                position: 'fixed',
                right: '45px',
                bottom: '42px',
                zIndex: '999',
                background: '#f6f6fc',
                border: '1px solid #d4d4d47d',
                padding: '8px 5px',
                borderRadius: '30px'
              }}>
          {!isFullscreen && (
            // <Button
            //   type="primary"
            //   label="Edit"
            //   buttonMode="button"
            //   onClick={() => {
            //     goto("/client/drag");
            //   }}
            // />
            <div className="edit-btn-dashboard" onClick={() => { goto("/client/drag"); }}>
                <img src={EditImg} alt=""   />
            </div>
          )}
          <button
            className="fullscreen-btn"
            onClick={handleFullscreenToggle}
            style={{
              background: "#ffffff",
              color: `${theme === "dark" ? "white" : "black"}`,
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              padding: '11px',
              borderRadius: '50px',
             lineHeight: '0'
            }}
            title="Toggle Fullscreen"
          >
            <FiMaximize />
          </button>
        </div>
      </div>

      <ReactGridLayout
        className="layout"
        layout={layout}
        cols={4}
        rowHeight={250}
        // width={1550}
        isDraggable={false} // Disable dragging
        isResizable={false} // Disable resizing
      >
        {widgets.map((widget) => (
          <div key={widget.id}>{renderWidgetComponent(widget.id)}</div>
        ))}
      </ReactGridLayout>

      {/* <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(400px, auto))",
          gap: "16px",
          padding: "16px",
        }}
      >
        {widgets.map((widget) => renderWidgetComponent(widget.id))}
      </div> */}
    </div>
  );
};

export default Dashboard;
