export const formatDate = (data) => {
  const date = new Date(data);

  // Format the date first
  const formattedDate = date.toLocaleString("en-GB", {
    year: "numeric",
    month: "short", // Use short month name like 'Sep'
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "UTC",
  });

  // Replace the ', ' separator between the date and time with ':'
  const finalFormat = formattedDate.replace(", ", ":");

  return finalFormat;
};

export function convertTo24Hour(time) {
  let [hours, minutes] = time.match(/\d+/g);
  const period = time.slice(-2);

  if (period === "PM" && hours !== "12") {
    hours = String(Number(hours) + 12);
  } else if (period === "AM" && hours === "12") {
    hours = "00";
  }

  return `${hours.padStart(2, "0")}:${minutes}`;
}
