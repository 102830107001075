import React, { useEffect, useState, useRef, useMemo } from "react";
import downloadImg from "../../../Assets/Images/export-icon.svg";
import EditImg from "../../../Assets/Images/edit-icon.svg";
import downDropImg from "../../../Assets/Images/down-drop.svg";
import lessThanImg from "../../../Assets/Images/less-than.svg";
import greaterThanImg from "../../../Assets/Images/greater-than.svg";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import { Form, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/file.svg";
import Button from "../../../Components/Button";
import exportIcon from "../../../Assets/Images/export-icon.svg";
import {
  customerConfigurationEmail,
  getServiceList,
  createCustomerConfiguration,
  customerConfigurationUpdate,
  getReportsList,
  getCustomerDevice,
  getNodesList,
  exportReports,
} from "../../../Service";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { convertTo24Hour } from "../../../Utils";

const Reports = () => {
  // const [isOpen, setIsOpen] = useState(false); // Dropdown visibility
  const [selectedDevice, setSelectedDevice] = useState("All");
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("last_24_hours");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState("");
  const [configureData, setconfigureData] = useState({});
  const [isEditing, setIsEditing] = useState(false); // Edit mode state
  const [form, setForm] = useState("");
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [nodeOptions, setNodeOptions] = useState([]);
  const [selectDeviceOptions, setSelectedDeviceOptions] = useState([]);
  const [selectNodeOptions, setSelectedNodeOptions] = useState([]);
  const [fields, setFields] = useState({
    emailSubject: "",
    emailBody: "",
    timeOfSending: "",
    timePeriod: "",
    yearOrMonth: "",
    configurefrequency: [],
    configureServices: [],
  });
  const [filterTable, setFilterTable] = useState({
    deviceId: [],
    nodeId: [],
    timePeriod: "",
    selectedMonth: "",
    selectedYear: "",
  });
  const [serviceData, setServiceData] = useState([]);

  const [options, setOptions] = useState([]);

  const [multiSelectOption, setMultiSelectOption] = useState([
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ]);
  const [selectedList, setSelectedList] = useState([]);

  const [isEdited, setIsEdited] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValues, setSelectedValues] = useState([-1]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    console.log(selectedValues, "Selected Value");
  }, [selectedValues]);

  // Show all options when search is empty
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredOptions(options);
    } else {
      let sortedOptions = options.filter((opt) =>
        opt.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Move checked items to the top
      sortedOptions.sort((a, b) => {
        const aChecked = selectedValues.includes(a.value) ? -1 : 1;
        const bChecked = selectedValues.includes(b.value) ? -1 : 1;
        return aChecked - bChecked;
      });

      setFilteredOptions(sortedOptions);
    }
  }, [searchTerm, selectedValues]);

  const handleCheckboxChange = (value) => {
    let updatedValues = [...selectedValues];

    if (value === -1) {
      // If "All" is selected again, toggle it OFF
      updatedValues = selectedValues.includes(-1) ? [] : [-1];
    } else {
      if (selectedValues.includes(value)) {
        updatedValues = updatedValues.filter((val) => val !== value);
      } else {
        updatedValues.push(value);
      }

      // If any item is selected, remove "All" from the list
      if (updatedValues.includes(-1)) {
        updatedValues = updatedValues.filter((val) => val !== -1);
      }
    }

    setSelectedValues(updatedValues);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log("selectedList", selectedList);
    console.log("Count", 1);
  }, [selectedList]);

  const fetchConfigureEmail = async () => {
    try {
      const response = await customerConfigurationEmail(); // Store API response

      setconfigureData(response);

      setFields({
        emailSubject: response.email_subject,
        emailBody: response.email_body,
        timeOfSending: convertTo24Hour(response.time_of_sending),
        configurefrequency: response.frequency,
        configureServices: response.services.map(
          (service) => service.service_id
        ),
      });
      console.log(
        "fetchConfigureEmail service data is",
        fields.configureServices
      );

      setSelectedOption(response.time_period);

      setSelectedOptions(
        response.services.map((service) => ({
          value: service.service_id,
          label: service.service_name,
        }))
      );
      console.log(
        "customerConfigurationEmail data is",
        customerConfigurationEmail
      );
    } catch (error) {
      console.error("customer Email Configuration", error);
    }
  };

  const currentYear = new Date().getFullYear();

  // Generate an array of years
  const years = Array.from({ length: 51 }, (_, index) => currentYear - index);

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const handleEditClick = () => {
    setIsEditing(true); // Enable editing
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    const formatTimeWithAMPM = (time) => {
      const [hour, minute] = time.split(":");
      let hours = parseInt(hour, 10);
      const suffix = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      const paddedHour = hours.toString().padStart(2, "0");
      return `${paddedHour}:${minute} ${suffix}`;
    };

    console.log("Service data is ", fields);

    // Prepare the data to be sent in the API request
    const requestData = {
      email_subject: fields.emailSubject,
      email_body: fields.emailBody,
      time_of_sending: formatTimeWithAMPM(fields.timeOfSending),
      frequency: fields.configurefrequency,
      services: fields.configureServices,
    };
    console.log("once clicking on handleSaveClick data is", requestData);
    try {
      if (configureData.is_post) {
        const response = await createCustomerConfiguration(requestData);
        console.log("Configuration posted  successfully:", response.data);
      } else {
        const response = await customerConfigurationUpdate(requestData);
        console.log("Configuration updated successfully:", response.data);
      }
      setIsEdited(!isEdited);
    } catch (error) {
      console.error("Failed to update configuration:", error);
    }
  };

  const handleChanges = (selected) => {
    setSelectedOptions(selected);
    setFields((prevFields) => ({
      ...prevFields,
      configureServices: selected.map((s) => s.value),
    }));
  };

  const handleInputChange = (e, fieldName) => {
    const { value, checked } = e.target;
    if (fieldName === "configurefrequency") {
      setFields((prevFields) => {
        const newFrequencies = checked
          ? [...prevFields.configurefrequency, value]
          : prevFields.configurefrequency.filter((freq) => freq !== value);
        return {
          ...prevFields,
          configurefrequency: newFrequencies,
        };
      });
    } else {
      setFields({
        ...fields,
        [fieldName]: e.target.value, // Dynamically update field
      });
    }
  };
  //

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage("report"));
  }, [dispatch]);

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        const data = await getServiceList();
        const services = data.services.map((service) => service.service_name);
        setItems(["All", ...services]);
        setData(data.services);
        const serviceOption = data.services.map((data) => {
          return {
            value: data.service_id,
            label: data.service_name,
          };
        });
        setOptions([{ value: -1, label: "All" }, ...serviceOption]);

        setFilteredOptions([{ value: -1, label: "All" }, ...serviceOption]);
      } catch (error) {
        console.log("Error fetching services:", error);
      }
    };

    fetchServiceList();
  }, []);

  useEffect(() => {
    const fetchCustomerDevices = async () => {
      try {
        const data = await getCustomerDevice();
        const options = data.device_data.map((device) => ({
          value: device.device_id,
          label: device.device_id,
        }));
        setDeviceOptions(options);
      } catch (error) {
        console.log("Error on device id's are", error);
      }
    };
    fetchCustomerDevices();
  }, []);

  useEffect(() => {
    const fetchNodeList = async () => {
      try {
        const data = await getNodesList();
        const options = data.objects.map((nodes) => ({
          value: nodes.id,
          label: nodes.name,
        }));
        setNodeOptions(options);
      } catch (error) {}
    };
    fetchNodeList();
  }, []);

  useEffect(() => {
    const fetchReportsServices = async () => {
      try {
        // const filterService = selectedValues.map((data) => {
        //   return String(data.value);
        // });

        console.log(selectedValues.toString(), "Service");

        const service_id = selectedValues.toString();
        const filter_type = filterTable.timePeriod;
        const year = filterTable.selectedYear;
        const month = filterTable.selectedMonth;
        const device_id = filterTable.deviceId.toString();
        const node_id = filterTable.nodeId.toString();
        const response = await getReportsList(
          service_id,
          filter_type,
          year,
          month,
          device_id,
          node_id
        );
        console.log("get data successfully", response);

        const formattedData = response.data.map((item) => ({
          id: item["Device ID"],
          node: item["Node Name"],
          service: item["Service Name"],
          progress: item["SLA Percentage"],
        }));

        setServiceData(formattedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReportsServices();
  }, [filterTable, selectedValues]);

  // function onChange(e) {
  //   const { name, value } = e.target;
  //   setForm((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // }

  // // Handle service selection change
  // function handleServiceChange(e) {
  //   const selectedService = data.find(
  //     (service) => service.service_name === e.target.value
  //   );
  //   setSelectedService(selectedService); // Set the selected service
  //   setForm((prev) => ({
  //     ...prev,
  //   }));
  // }

  // // Handle parameter input change
  // function handleParameterChange(id, value) {
  //   setForm((prev) => ({
  //     ...prev,
  //     parameters: {
  //       ...prev.parameters,
  //       [id]: value, // Map each parameter ID to its value
  //     },
  //   }));
  // }

  // select options

  // const items = [
  //   "All",
  //   "Website Up/Down Time",
  //   "APC UPS - Bypass Status",
  //   "APC UPS Battery Level",
  //   "Hikvision IP Camera Status",
  //   "APC UPS - Battery Voltage",
  //   "APC UPS - Battery Temperature",
  //   "APC UPS - Output Voltage",
  //   "APC UPS - UPS Status",
  //   "APC UPS - Runtime Remaining",
  //   "Connectivity",
  //   "Website Response Time",
  //   "APC UPS - Load Percentage",
  //   "Hikvision IP Camera Free Memory",
  //   "APC UPS - Input Voltage",
  //   "APC UPS - Battery Charge Status",
  //   "SOPHOS Port Download Speed",
  //   "SOPHOS Port Upload Speed",
  // ];
  useEffect(() => {
    fetchConfigureEmail();
  }, [isEdited]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // end

  // Services multi select
  // const handleChanges = (selected) => {
  //   if (selected.some((option) => option.value === "All")) {
  //     // If "All" is selected, allow only "All" and remove others from selection
  //     setSelectedOptions([{ value: "All", label: "All" }]);
  //   } else {
  //     // If "All" is not selected, set the selected options as usual
  //     setSelectedOptions(selected);
  //   }
  // };

  // Format selected options for displaying
  // const getSelectedTexts = () => {
  //   if (selectedOptions.length === 0) {
  //     return "Please select options";
  //   }
  //   return selectedOptions.map((option) => option.label).join(", ");
  // };

  // Filter
  const handleApply = () => {
    let filterText = "";
    if (selectedOption === "last_24_hrs") {
      filterText = "Time Period - last_24_hrs";
    } else if (selectedOption === "monthly") {
      filterText = `Time Period - Monthly (${selectedMonth} ${selectedYear})`;
    } else if (selectedOption === "yearly") {
      filterText = `Time Period - Yearly (${selectedYear})`;
    }

    console.log(filterText, "Text");

    setFilterTable({
      deviceId: selectDeviceOptions.map((devices) => devices.value),
      nodeId: selectNodeOptions.map((nodes) => nodes.value),
      timePeriod: selectedOption,
      selectedMonth: selectedMonth,
      selectedYear: selectedYear,
    });

    console.log("Applied filters are", {
      deviceId: selectDeviceOptions.map((devices) => devices.value),
      nodeId: selectNodeOptions.map((nodes) => nodes.value),
      timePeriod: selectedOption,
      selectedMonth: selectedMonth,
      selectedYear: selectedYear,
    });

    setAppliedFilters(filterText); // Save applied filter
    setFilterShow(false); // Close modal after applying
  };

  const handleClear = () => {
    setSelectedOption("");
    setSelectedMonth("");
    setSelectedYear("");
    setAppliedFilters("");
    setSelectedDeviceOptions([]);
    setSelectedNodeOptions([]);
    setFilterShow(false);
    setFilterTable({
      deviceId: [],
      nodeId: [],
      timePeriod: "",
      selectedMonth: "",
      selectedYear: "",
    });
  };

  const handleCancel = () => {
    setFilterShow(false);
  };
  //
  // Define Columns
  // table
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    console.log("selectedItems updated:", selectedItems);

    const baseColumns = [
      { accessorKey: "id", header: "Device ID", size: 150 },
      { accessorKey: "node", header: "Node", size: 150 },
    ];

    if (selectedItems.includes("All") || selectedItems.length > 1) {
      baseColumns.push({
        accessorKey: "service",
        header: "Service",
        size: 200,
      });
    }

    baseColumns.push({
      accessorKey: "progress",
      header: "SLA Percentage",
      size: 200,
      Cell: ({ cell }) => (
        <div className="progress-bar__wrapper">
          <label className="progress-bar__value">{cell.getValue()}%</label>
          <progress value={cell.getValue()} max="100"></progress>
        </div>
      ),
    });

    setColumns(baseColumns);
  }, [selectedItems]);

  const table = useMaterialReactTable({
    data: serviceData || [],
    columns,
    enableRowSelection: true, // Optional: Enable row selection
    initialState: {
      columnOrder: [
        "id",
        "node",
        "service", // Ensure "Service" always comes third
        "progress",
        "mrt-row-select", // Move selection column to the end
      ],
    },
  });

  // table end
  return (
    <div>
      {/* Header Section */}
      <div className="d-flex align-items-center justify-content-between my-2 respo-flex">
        {/* <h4 className="main-title">Reports</h4> */}
      </div>

      {/* Configuration Section */}
      <div className="bg-white bg-space-radius">
        {/* Header Section */}
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h5 className="sub-title">Configuration</h5>
          {!isEditing ? (
            <a href="#" onClick={handleEditClick}>
              <img src={EditImg} alt="Edit" />
            </a>
          ) : (
            <div className="d-flex align-items-center">
              <button
                onClick={() => {
                  setIsEditing(false);
                }}
                className="button-common btn-outline-primary button-icon me-4"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveClick}
                className="button-common btn-primary button-icon"
              >
                Save
              </button>
            </div>
          )}
        </div>

        {/* Content Section */}

        <div className="report-design-style">
          <div className="section-left">
            {/* Email ID */}
            {isEditing ? (
              // <div className="d-flex align-items-center">
              //   <p className="ques-left">Email ID:</p>
              //   <div className="ans-right">
              //       <input
              //         type="email"
              //         className="form-control"
              //         value={fields.emailId}
              //         onChange={(e) => handleInputChange(e, "emailId")}
              //       />
              //   </div>
              // </div>
              <></>
            ) : (
              <div className="d-flex align-items-center">
                <p className="ques-left">Email ID:</p>
                <div className="ans-right">
                  <p className="mb-0">
                    {configureData.email_id ? configureData.email_id : "-"}
                  </p>
                </div>
              </div>
            )}

            {/* Name of receiver */}
            {isEditing ? (
              // <div className="d-flex align-items-center">
              //   <p className="ques-left">Name of receiver:</p>
              //   <div className="ans-right">

              //       <input
              //         type="text"
              //         className="form-control"
              //         value={fields.receiverName}
              //         onChange={(e) => handleInputChange(e, "receiverName")}
              //       />
              //   </div>
              // </div>
              <></>
            ) : (
              <div className="d-flex align-items-center">
                <p className="ques-left">Name of receiver:</p>
                <div className="ans-right">
                  <p className="mb-0">
                    {configureData ? configureData.name_of_receiver : "-"}
                  </p>
                </div>
              </div>
            )}

            {/* Email Subject */}
            <div className="d-flex align-items-center">
              <p className="ques-left">Email Subject:</p>
              <div className="ans-right">
                {isEditing ? (
                  <input
                    type="text"
                    className="form-control"
                    // value={configureData.email_subject}
                    value={fields.emailSubject || ""}
                    onChange={(e) => handleInputChange(e, "emailSubject")}
                  />
                ) : (
                  <p className="mb-0">
                    {configureData.email_subject
                      ? configureData.email_subject
                      : "-"}
                  </p>
                )}
              </div>
            </div>

            {/* Email Body */}
            <div className="d-flex align-items-start">
              <p className="ques-left">Email Body:</p>
              <div
                className="ans-right"
                style={{ maxHeight: "200px", overflow: "auto" }}
              >
                {isEditing ? (
                  <textarea
                    rows="4"
                    cols="50"
                    className="form-control"
                    value={fields.emailBody || ""}
                    onChange={(e) => handleInputChange(e, "emailBody")}
                  ></textarea>
                ) : (
                  <p className="mb-0">
                    {configureData.email_body ? configureData.email_body : "-"}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="section-right">
            {/*Frequency section */}
            <div className="d-flex align-items-center">
              <p className="ques-left">Frequency:</p>
              <div className="ans-right">
                {isEditing ? (
                  <div className="frequency-selector">
                    {["Daily", "Weekly", "Monthly", "Yearly"].map(
                      (frequency) => (
                        <div key={frequency}>
                          <label>
                            <input
                              type="checkbox"
                              value={frequency}
                              checked={fields.configurefrequency.includes(
                                frequency
                              )}
                              onChange={(e) =>
                                handleInputChange(e, "configurefrequency")
                              }
                            />
                            {frequency}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p className="mb-0">
                    {configureData.frequency
                      ? configureData.frequency.toString()
                      : "-"}
                  </p>
                )}
              </div>
            </div>

            {/* Time of sending */}
            <div className="d-flex align-items-center">
              <p className="ques-left">Time of sending:</p>
              <div className="ans-right">
                {isEditing ? (
                  <input
                    type="time"
                    id="appt"
                    className="form-control"
                    value={fields.timeOfSending}
                    onChange={(e) => handleInputChange(e, "timeOfSending")}
                  />
                ) : (
                  <p className="mb-0">
                    {configureData.time_of_sending
                      ? configureData.time_of_sending
                      : "-"}
                  </p>
                )}
              </div>
            </div>

            {/* Frequency */}
            {/* <div className="d-flex align-items-center">
                <p className="ques-left">Frequency:</p>
                <div className="ans-right">
                  {isEditing ? (
                    <>
                      <div className="frequency-selector">
                        <div>
                          <label>
                            <input type="checkbox" value="Daily" />
                            Daily
                          </label>
                        </div>
                        <div>
                          <label>
                            <input type="checkbox" value="Weekly" />
                            Weekly
                          </label>
                        </div>
                        <div>
                          <label>
                            <input type="checkbox" value="Monthly" />
                            Monthly
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    //
                    <p className="mb-0">{configureData.frequency}</p>
                  )}
                </div>
              </div> */}

            {/* services */}
            <div className="d-flex align-items-center">
              <p className="ques-left">Services:</p>
              <div className="ans-right w-100">
                {isEditing ? (
                  <Select
                    isMulti
                    placeholder="Please Select"
                    value={selectedOptions}
                    onChange={handleChanges}
                    options={options}
                    isSearchable
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                ) : (
                  //
                  // <p className="mb-0"> {configureData.services.service_name} </p>
                  // <p className="mb-0">
                  //   {configureData.services
                  //     ? configureData.services
                  //         .map((service) => service.service_name)
                  //         .join(", ")
                  //     : "No services selected"}
                  // </p>
                  <p className="mb-0">
                    {configureData && configureData.services
                      ? configureData.services
                          .map((service) => service.service_name)
                          .join(",")
                      : "-"}
                  </p>
                )}
              </div>
            </div>

            {/* <Form.Group className="mb-3 d-flex align-items-center">
                <p className="ques-left">Services:</p>
                <div className="ans-right w-100">
                  {isEditing ? (
                    <Form.Select
                      value={form.service_name}
                      name="service_name"
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="All">All</option>
                      {data.map((service) => (
                        <option
                          key={service.service_id}
                          value={service.service_name}
                        >
                          {service.service_name}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <p className="mb-0">{configureData.services}</p>
                  )}
                </div>
              </Form.Group> */}
            {/* end services */}
          </div>
        </div>
      </div>

      {/* Dropdown Section */}
      <div className="bg-white bg-space-radius mt-4">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h6 className="sub-title me-4">Services SLA</h6>
            {appliedFilters && (
              <div className="applied-filters">
                <h6 className="mb-0">{appliedFilters}</h6>
              </div>
            )}
            {/* <h6> Time Period - Last 24 -hours </h6>
              <h6> Time Period - Monthly (Jan 2024) </h6>
              <h6> Time Period - Yearly (2024) </h6> */}
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap filter-img filter-style">
            <div className="bg-filter-style">
              <Button
                icon={filterIcon}
                label="Filter "
                type="primary"
                onClick={() => {
                  setFilterShow(true);
                }}
              />
            </div>

            {/*  */}
            {/* <div
              className="ms-4"
              style={{ position: "relative" }}
              ref={dropdownRef}
            >
              <button
                className="dropdown-devices"
                type="button"
                onClick={toggleDropdown}
                style={{
                  textAlign: "left",
                  padding: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
                title={getSelectedText()}
              >
                <span>{getSelectedText()}</span>
                <div className="dropdown-icon">
                  <img
                    src={downDropImg}
                    alt="Dropdown"
                    style={{ width: "13px" }}
                  />
                </div>
              </button>

              {isOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    width: "100%",
                    zIndex: 1000,
                    backgroundColor: "white",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  }}
                >
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search..."
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                    }}
                  />

                  <ul
                    style={{
                      listStyle: "none",
                      margin: "0",
                      padding: "0",
                      maxHeight: "265px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {filteredItems
                      .sort(
                        (a, b) =>
                          selectedItems.includes(b) - selectedItems.includes(a)
                      )
                      .map((item, index) => (
                        <li key={index} title={item}>
                          <button
                            className="dropdown-item"
                            onClick={() => handleItemClick(item)}
                            style={{
                              border: "none",
                              background: selectedItems.includes(item)
                                ? "#f0f0f0"
                                : "none",
                              width: "100%",
                              textAlign: "left",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(item)}
                              readOnly
                              style={{ marginRight: "10px" }}
                            />
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div> */}
            {/*  */}

            {/* <Multiselect
              options={options}
              showCheckbox="true"
              onSelect={(selectedList) => {
                setSelectedList(selectedList);
              }}
              placeholder="Select Services"
              hideSelectedList={true} // Hides selected items from the input field
              // avoidHighlightFirstOption={true} // Prevents auto selection
              // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
              // onSelect={this.onSelect} // Function will trigger on select event
              // onRemove={this.onRemove} // Function will trigger on remove event
              displayValue="label"
            /> */}

            <div
              className="relative w-72"
              ref={dropdownRef}
              style={{ width: "250px" }}
            >
              {/* Dropdown Trigger */}
              <div
                className=" px-3 py-2 border rounded-md cursor-pointer bg-white"
                style={{ maxHeight: "60px", overflow: "auto" }}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedValues.length > 0
                  ? selectedValues
                      .map(
                        (val) => options.find((opt) => opt.value === val)?.label
                      )
                      .join(", ")
                  : "Select options..."}
              </div>

              {isOpen && (
                <div
                  className=" mt-2 bg-white border rounded-md shadow-md z-10"
                  style={{
                    position: "absolute",
                    zIndex: "10",
                    width: "250px",
                    height: "400px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {/* Search Input */}
                  <input
                    type="text"
                    className=" px-3 py-2 border-b outline-none"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: "250px" }}
                  />

                  {/* Options List (One per row, scrollable) */}
                  <div className="max-h-60 overflow-y-auto">
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map((option) => (
                        <label
                          key={option.value}
                          className={`flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 ${
                            selectedValues.includes(option.value)
                              ? "bg-gray-100"
                              : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            className="mr-1 me-2"
                            checked={selectedValues.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                          />
                          {option.label}
                        </label>
                      ))
                    ) : (
                      <div className="px-3 py-2 text-gray-500">
                        No options found
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <button
              className="button-common button-icon secondary ms-4"
              onClick={async () => {
                try {
                  const service_id = selectedValues.toString();
                  const filter_type = filterTable.timePeriod;
                  const year = filterTable.selectedYear;
                  const month = filterTable.selectedMonth;
                  const device_id = filterTable.deviceId.toString();
                  const node_id = filterTable.nodeId.toString();

                  const response = await exportReports(
                    service_id,
                    filter_type,
                    year,
                    month,
                    device_id,
                    node_id
                  );

                  const downloadUrl = response.download_url;

                  if (downloadUrl) {
                    // Fetch the file
                    const fileResponse = await fetch(downloadUrl);
                    const blob = await fileResponse.blob();
                    const blobUrl = URL.createObjectURL(blob);

                    // Create an anchor element
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = "Report.pdf"; // Ensure a proper filename

                    // Append to DOM and trigger click
                    document.body.appendChild(link);
                    link.click();

                    // Cleanup
                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl); // Free memory
                  }
                } catch (error) {
                  console.error("Error exporting reports:", error);
                }
              }}
            >
              <img
                src={downloadImg}
                alt="Download"
                style={{ width: "12px" }}
                className="me-2"
              />
              Export
            </button>

            {/* <Button  
           onClick={ async () => {
            try{
              const response = await exportReports(
                 filterTable.service_id,
                 filterTable.filter_type,
                 filterTable.year,
                 filterTable.month,
                 filterTable.deviceId,
                 filterTable.nodeId
            );
            const downloadUrl = response.download_url;

            if(downloadUrl){
              const link = document.createElement("a");
              link.href =  downloadUrl;

              link.download = "Reports_export.csv";

              document.body.appendChild(link);
              link.click();

              document.body.appendChild(link);
            }

            }catch(error){
              console.error("Error exporting reports:", error);
            }
           }} 
           icon={exportIcon}
           label="Export"
           type="secondary"/> */}
          </div>
        </div>
        {/* Table */}

        <div className="table-responsive reports-table">
          <MaterialReactTable table={table} />
        </div>

        {/* End Table */}
        {/* Table Section */}
        {/* <div className="table-responsive mt-4">
          <table className="table text-start table-style">
            <thead>
              <tr className="table-header">
                <th>Device ID</th>
                <th>Node</th>
                {selectedItems.includes("All") && <th>Service</th>}
                <th>SLA Percentage</th>
              </tr>
            </thead>
            <tbody>
              {serviceData.map((stat, index) => (
                <tr key={index}>
                  <td>{stat.id}</td>
                  <td>{stat.node}</td>
                  {selectedItems.includes("All") && <td>{stat.service}</td>}
                  <td>
                    <div className="progress-bar__wrapper">
                      <label
                        className="progress-bar__value"
                        htmlFor={`progress-bar-${index}`}
                      >
                        {stat.progress}%
                      </label>
                      <progress
                        id={`progress-bar-${index}`}
                        value={stat.progress}
                        max="100"
                      ></progress>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

        {/* Pagination */}
        {/* <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a className="page-link border-0" href="#" aria-label="Previous">
                <span aria-hidden="true">
                  <img src={lessThanImg} alt="Previous" />
                </span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link active" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link border-0" href="#" aria-label="Next">
                <span aria-hidden="true">
                  <img src={greaterThanImg} alt="Next" />
                </span>
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
      {/*  */}
      <Modal
        show={filterShow}
        onHide={handleCancel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="time-period-selector mb-3">
            <DropdownButton
              id="dropdown-time-period"
              className="dropdownnew-style modal-dropdownnew-style"
              title={selectedOption || "Select Time Period"}
              onSelect={(value) => setSelectedOption(value)}
            >
              <Dropdown.Item eventKey="last_24_hrs">
                Last 24 Hours
              </Dropdown.Item>
              <Dropdown.Item eventKey="monthly">Monthly</Dropdown.Item>
              <Dropdown.Item eventKey="yearly">Yearly</Dropdown.Item>
            </DropdownButton>

            {selectedOption === "monthly" && (
              <div className="d-flex align-items-center justify-content-start mb-0">
                <DropdownButton
                  className="mt-0 ms-4  dropdownnew-style modal-dropdownnew-style"
                  id="dropdown-month"
                  title={
                    months.find((month) => month.value === selectedMonth)
                      ?.label || "Select Month"
                  }
                  onSelect={(value) => setSelectedMonth(value)}
                >
                  {months.map((month) => (
                    <Dropdown.Item key={month.value} eventKey={month.value}>
                      {month.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <DropdownButton
                  className="mt-0 ms-4  dropdownnew-style height-set modal-dropdownnew-style"
                  id="dropdown-year"
                  title={selectedYear || "Select Year"}
                  onSelect={(value) => setSelectedYear(value)}
                >
                  {years.map((year) => (
                    <Dropdown.Item key={year} eventKey={year}>
                      {year}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            )}

            {selectedOption === "yearly" && (
              <DropdownButton
                className="mt-0 ms-4 dropdownnew-style height-set modal-dropdownnew-style"
                id="dropdown-year"
                title={selectedYear || "Select Year"}
                onSelect={(value) => setSelectedYear(value)}
              >
                {years.map((year) => (
                  <Dropdown.Item key={year} eventKey={year}>
                    {year}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            )}
          </div>
          <div className="mb-4 d-flex align-items-center justify-content-between w-100">
            <div className="w-100 me-1">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="device id"
                options={deviceOptions}
                placeholder="Select Device ID..."
                isMulti
                onChange={(selected) => setSelectedDeviceOptions(selected)}
                value={selectDeviceOptions}
              />
            </div>
            <div className="w-100 ms-1">
              <Select
                className="basic-single w-100"
                classNamePrefix="select"
                isSearchable={true}
                name="device id"
                options={nodeOptions}
                placeholder="Select Node..."
                isMulti
                onChange={(selected) => setSelectedNodeOptions(selected)}
                value={selectNodeOptions}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end w-100">
            <button
              className="button-common primary large-button  large-button me-4"
              onClick={() => handleApply(false)}
            >
              Apply
            </button>
            <button
              className="button-common secondary large-button  large-button me-4"
              onClick={() => handleClear(false)}
            >
              Clear
            </button>
            <button
              className="button-common secondary large-button  large-button"
              onClick={() => handleCancel(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/*  */}
    </div>
  );
};

export default Reports;
