import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../../Redux/themeSlice";
import DarkImg from "../../Assets/Images/dark.svg";
import LightImg from "../../Assets/Images/light.svg";
import DeleteImg from "../../Assets/Images/delete.svg";
import NotificationImg from "../../Assets/Images/notification-new.svg";
import DarkWhiteImg from "../../Assets/Images/white-dark.svg";
import Close from "../../Assets/Images/close-notify.svg";
import LightWhiteImg from "../../Assets/Images/white-light.svg";
import { Dropdown } from "react-bootstrap";
import { setUnreadCount } from "../../Redux/notificationSlice";
import { setActivePage } from "../../Redux/reducer";
import {
  adminNotificationList,
  adminNotificationReadAll,
  customerNotificationList,
  customerNotificationReadAll,
} from "../../Service";
import deleteIcon from "../../Assets/Images/delete.svg";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import moment from "moment";


const ThemeToggleButton = () => {
  const theme = useSelector((state) => state.theme.mode);
  const dispatch = useDispatch();
  const [tickets, setTickets] = useState([]);
  const [newTicket, setNewTicket] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const unreadCount = useSelector((state) => state.notification.unreadCount);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const goto = useNavigate();
 

  const location = useLocation();
  const pathNameLink = location.pathname;
  

  const user = localStorage.getItem("user");

  useEffect(() => {
    console.log(unreadCount, "notification Count");
  }, [unreadCount]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  // useEffect(() => {
  //   const fetchNotificationList = async () => {
  //     try {
  //       const data = await adminNotificationList();
  //       setTickets(data);
  //       const unread = data.filter((ticket) => !ticket.is_read).length; // Calculate unread tickets
  //       dispatch(setUnreadCount(unread)); // Update unread count
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   if (user === "Admin") fetchNotificationList();

  //   return async () => {
  //     try {
  //       await adminNotificationReadAll();
  //       await fetchNotificationList();
  //     } catch {}
  //   };
  // }, [dispatch, newTicket]);

  const toggleDropdown = async () => {
    setIsDropdownOpen((prev) => !prev);
    if (isDropdownOpen === true) {
      await markNotificationsAsRead();
    }
  };


  const TicketTime = ({ timestamp }) => {

    const ticketDate = moment(timestamp);
    const today = moment().startOf("day");  // Get today's start time (midnight)
    return (
      <span style={{ fontSize: "14px" }}>
        {ticketDate.isSame(today, "day") ? ticketDate.fromNow(): ticketDate.format("MMM DD, YYYY [at] hh:mm A")}
      </span>
    );
  };

  // // useEffect to mark notifications as read when dropdown opens
  // useEffect(() => {

  // }, [isDropdownOpen]); // Runs when `isDropdownOpen` changes

  const markNotificationsAsRead = async () => {
    try {
      if (user === "Admin") {
        await adminNotificationReadAll();
        await fetchNotificationList();
        console.log("Admin notifications marked as read");
      } else {
        await customerNotificationReadAll();
        await fetchCustomerNotificationList();
        console.log("Customer notifications marked as read");
      }
    } catch (error) {
      console.error("Failed to mark notifications as read", error);
    }
  };

  const fetchNotificationList = async () => {
    try {
      const data = await adminNotificationList();
      setTickets(data);
      const unread = data.filter((ticket) => !ticket.is_read).length; // Calculate unread tickets
      dispatch(setUnreadCount(unread)); // Update unread count
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomerNotificationList = async () => {
    try {
      const data = await customerNotificationList();
      setTickets(data);
      const unread = data.filter((ticket) => !ticket.is_read).length; // Calculate unread tickets
      dispatch(setUnreadCount(unread)); // Update unread count
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user === "Admin") {
      fetchNotificationList();
    } else {
      fetchCustomerNotificationList();
    }
  }, [dispatch, newTicket]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_SOCKET_URL); // Adjust the URL to match your backend
    if (user === "Admin") {
      //connect to the  Socket.IO server

      //listen for the 'connect' event
      socket.on("connect", () => {
        console.log("Connected to Socket.IO server");
        console.log("Socket ID:", socket.id);
        setSocketConnected(true);

        //optionally, join a specific room
        socket.emit("join", { room: "admin_room" });
        if (user === "Admin") fetchNotificationList();
      });

      //Handle connection error
      socket.on("connect_error", (error) => {
        console.error("connection failed:", error);
      });

      //Listen for the 'new_ticket' event
      socket.on("new_ticket", (data) => {
        console.log("New ticket received:", data);
        fetchNotificationList();
        // setTickets((prevTickets) => [...prevTickets, data]); // Add new ticket to the state
        setNewTicket(!newTicket);
      });

      //Listen for the 'new_ticket_comment' event for customer
      socket.on("new_ticket_comment", (data) => {
        console.log("New ticket received:", data);
        fetchCustomerNotificationList();
        // setTickets((prevTickets) => [...prevTickets, data]); // Add new ticket to the state
        setNewTicket(!newTicket);
      });

      // Handle disconnections
      socket.on("disconnect", () => {
        console.log("Disconnected from the server");
        setSocketConnected(false);
      });
    }

    //cleanup the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="w-100 d-flex align-items-center justify-content-between">
      {/* <button onClick={() => dispatch(toggleTheme())} style={{ background: 'transparent',
    border: '0',
    boxShadow: 'none',
    color: '#fff'}}>
      Switch to {theme === "light" ? "Dark" : "Light"} Mode
    </button> */}
      {/*  */}
      {/* <Form>
      <Form.Check
        type="switch"
        id="custom-switch-1"
        label={theme === "light" ? "Light Mode" : "Dark Mode"} // Optional label
        checked={theme === "dark"} // Reflect the current theme in the switch state
        onChange={() => dispatch(toggleTheme())} // Toggle theme on change
      />
    </Form> */}

      {/*  */}
      <div>
        <h4 className="sub-title">
           {pathNameLink === '/client/my-device' ? 'My Devices' : pathNameLink === '/client/monitoring' ? 'Tasks' : pathNameLink === '/client/report' ? 'Reports' : pathNameLink === '/client/nodes' ? 'Nodes' : pathNameLink === '/client/raise-ticket' ? 'Raise Tickets' : pathNameLink === '/client/profile' ? 'Profile' : pathNameLink === '/client' ? 'Overview' : pathNameLink === '/client/notification' ? 'Notification' : pathNameLink === '/admin' ? 'Customers' : 
           pathNameLink === '/admin/devices' ? 'Devices' : pathNameLink === '/admin/services' ? 'Tasks' : pathNameLink === '/admin/reports' ? 'Reports' : pathNameLink === '/admin/tickets' ? 'Tickets' : pathNameLink === '/client/drag' ? 'Overview' : '' }
        </h4>
      </div>
      <div className="d-flex align-items-center justify-content-end">
      <div
        className="me-4 position-relative notification-box"
        style={{ cursor: "pointer" }}
      >
        {/* <img src={NotificationImg} alt="" />
        <div className="notification-style">
               <p className="mb-0">  45 </p>
        </div> */}

        <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
          <Dropdown.Toggle
            id="dropdown-notifications"
            as="div"
            style={{ cursor: "pointer" }}
            onClick={toggleDropdown}
          >
            <div>
              <img src={NotificationImg} alt="Notifications" />
              {unreadCount !== 0 && (
                <div className="notification-style">
                  <p className="mb-0">{unreadCount}</p>
                </div>
              )}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <div
              className="d-flex align-items-center w-100 justify-content-between p-3"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <div className="d-flex align-items-center">
                <h5 className="mb-0" style={{ color: "#082064" }}>
                  Notification
                </h5>
                <div className="inside-notify">{tickets.length}</div>
              </div>
              <div>{/* <img src={Close} alt="" /> */}</div>
            </div>

            <div id="ticket-list">
              {tickets.map((ticket, index) => (
                <div
                  key={index}
                  className={`notification-container ${
                    ticket.is_read ? "read" : "unread"
                  }`}
                >
                  <div
                    className="d-flex align-items-center w-100 justify-content-between"
                    onClick={() => {
                      user === "Admin" ? goto("/admin/tickets") : goto("/client/raise-ticket");
                    }}
                  >
                    <div>
                      <p className="mb-0 hightlight-notify-read">
                        <span
                          className={`me-2 ${
                            ticket.is_read ? "hightlight-notify-read" : "hightlight-notify"
                          }`}
                        >
                          {ticket.data?.created_by}
                        </span>
                        <span className="me-2">raised a</span>
                        <span
                            className={`${
                              ticket.is_read ? "hightlight-notify-read" : "hightlight-notify"
                            }`}
                        >
                          new {ticket.data.type}
                        </span>
                        <br />
                        {user !== "Admin" && <span className="me-2">for</span>}
                        <span
                         className={`${
                          ticket.is_read ? "hightlight-notify-read" : "hightlight-notify"
                        }`}
                        >
                          {ticket.data.ticket_id}
                        </span>
                        <br />
                        <span style={{ fontSize: "14px" }}>
                        <TicketTime timestamp={ticket.data.timestamp} />
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="notification-delete">
                    <img src={deleteIcon} alt="" style={{ width: "15px" }} />
                  </div>
                </div>
              ))}
            </div>

            {/* <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2 hightlight-notify">
                    Robin123@gmail.com
                  </span>
                  <span className="me-2">raised a</span>
                  <span className="hightlight-notify">new ticket</span>
                  <br />
                  <span>5 min ago</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item> */}

            {/* <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2 hightlight-notify">
                    Robin123@gmail.com
                  </span>
                  <span className="me-2">raised a</span>
                  <span className="hightlight-notify">new ticket</span>
                  <br />
                  <span>5 min ago</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2">Robin123@gmail.com</span>
                  <span className="me-2">raised a</span>
                  <span className="">new ticket</span>
                  <br />
                  <span>2024-12-09</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2 ">Robin123@gmail.com</span>
                  <span className="me-2">raised a</span>
                  <span className="">new ticket</span>
                  <br />
                  <span>2024-12-09</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2">Robin123@gmail.com</span>
                  <span className="me-2">raised a</span>
                  <span className="">new ticket</span>
                  <br />
                  <span>2024-12-09</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2">Robin123@gmail.com</span>
                  <span className="me-2">raised a</span>
                  <span className="">new ticket</span>
                  <br />
                  <span>2024-12-09</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2">Robin123@gmail.com</span>
                  <span className="me-2">raised a</span>
                  <span className="">new ticket</span>
                  <br />
                  <span>2024-12-09</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item href="#">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="mb-0 hightlight-notify-read">
                  <span className="me-2">Robin123@gmail.com</span>
                  <span className="me-2">raised a</span>
                  <span className="">new ticket</span>
                  <br />
                  <span>2024-12-09</span>
                </p>
                <div>
                  <img src={DeleteImg} alt="" />
                </div>
              </div>
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        onClick={() => dispatch(toggleTheme())}
        style={{
          background: theme === "light" ? "#EDEDF0" : "#161C29",
          borderRadius: "15px",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          width: "80px",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
        }}
      >
        {/* Left Icon (Moon for dark mode, Sun for light mode) */}
        <div className="me-2">
          <img
            src={theme === "light" ? DarkImg : DarkWhiteImg}
            alt={theme === "light" ? "Dark Mode Icon" : "Light Mode Icon"}
            style={{
              width: "30px",
              background: theme === "light" ? "transparent" : "#252D3E",
              padding: "6px",
              borderRadius: "30px",
            }}
          />
        </div>

        {/* Right Icon (Sun for light mode, Moon for dark mode) */}
        <div>
          <img
            src={theme === "light" ? LightImg : LightWhiteImg}
            alt={theme === "light" ? "Light Mode Icon" : "Dark Mode Icon"}
            style={{
              width: "30px",
              background: theme === "light" ? "#fff" : "transparent",
              padding: "6px",
              borderRadius: "30px",
            }}
          />
        </div>
      </div>
      </div>
    </div>
  );
};

export default ThemeToggleButton;
